
body {
  background-color: #f2f2f2;
    font-family: 'Lato', sans-serif;
    font-weight: 300;
    font-size: 16px;
    color: #434445;

    -webkit-font-smoothing: antialiased;
    -webkit-overflow-scrolling: touch;
}

/* Titles */
h1, h2, h3, h4, h5, h6 {
    font-family: 'Lato', sans-serif;
    font-weight: 300;
    color: #434445;
}

h1 {
	font-size: 40px;
}

h3 {
	color: #434445;
	font-weight: 400;
}

h4 {
	color: #434445;
	font-weight: 400;
	font-size: 20px;
}

/* Paragraph & Typographic */
p {
    line-height: 28px;
    margin-bottom: 25px;
    font-size: 16px;
}

.centered {
    text-align: center;
}

/* Links */
a {
    color: #073350;
    word-wrap: break-word;

    -webkit-transition: color 0.1s ease-in, background 0.1s ease-in;
    -moz-transition: color 0.1s ease-in, background 0.1s ease-in;
    -ms-transition: color 0.1s ease-in, background 0.1s ease-in;
    -o-transition: color 0.1s ease-in, background 0.1s ease-in;
    transition: color 0.1s ease-in, background 0.1s ease-in;
}

a:hover,
a:focus {
    color: #7b7b7b;
    text-decoration: none;
    outline: 0;
}

a:before,
a:after {
    -webkit-transition: color 0.1s ease-in, background 0.1s ease-in;
    -moz-transition: color 0.1s ease-in, background 0.1s ease-in;
    -ms-transition: color 0.1s ease-in, background 0.1s ease-in;
    -o-transition: color 0.1s ease-in, background 0.1s ease-in;
    transition: color 0.1s ease-in, background 0.1s ease-in;
}

 hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}

.navbar-default {
	background-color: #3498db;
	border-color: transparent;
}

.navbar-default .navbar-brand {
	color: white;
}

.navbar-default .navbar-nav > li > a {
	color: white;
}

/* Helpers */

.mt {
	margin-top: 40px;
	margin-bottom: 40px;
}

.form-control {
	height: 42px;
	font-size: 18px;
	width: 280px;
}

i {
	margin: 8px;
	color: #3498db; 
}


/* HeaderWrap */
#headerwrap {
	/* background: url(../img/bg01.jpg) no-repeat center top; */
	background-color: #3498db;
	margin-top: -20px;
	padding-top:200px;
	background-attachment: relative;
	background-position: center center;
	min-height: 650px;
	width: 100%;
	
    -webkit-background-size: 100%;
    -moz-background-size: 100%;
    -o-background-size: 100%;
    background-size: 100%;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

#headerwrap h1 {
	margin-top: 60px;
	margin-bottom: 15px;
	color: white;
	font-size: 45px;
	font-weight: 300;
	letter-spacing: 1px;
}
#prayerViewPage.errorMessage {
  padding: 10px;
}

.form-control {
  width: 100%;
}

.full-control {
  width:100%;
}

.navbar-nav li a {
  cursor: pointer;
}

.react-datepicker__input-container {
  width: 100%;
}

.banner-responsive {
  max-width: 100%;
}

.help-icon{
  cursor: pointer;
}

.help-container {
  display: block;
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  margin-bottom: 10px;
  background-color: #fffde7;
  border-radius: 3px;
}

.chart-title {
  font-weight: bold;
  font-size: 18px;
}

.card {
  margin-bottom: 10px;
}

.navbar {
  background-color: #073350;
}

.nav-link {
  color: white !important;
  font-weight: bold;
}

.sub-nav-link-active {
  text-decoration: underline;
  font-weight: bold;
}

.nav-tabs {
  margin-bottom: 10px;
}

.nav-tabs .nav-item {
  background-color: #114f79;
  border-color: #073350;
  margin-right: 3px;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  border-color: #114f79;
  background-color: #073350;
}

.btn-primary {
  background-color: #073350;
}

.list-row {
  border-bottom-color: #777;
  border-bottom-width: 2px;
  margin-bottom: 5px;
}

.list-row-header {
  font-weight: bold;
  text-decoration: underline;
}

/* icons */
.icon {
  font-size: 14px;
  margin-left: 5px;
  margin-right: 5px;
}

.icon-primary {
  color: #073350;
}

.icon-danger {
  color: #dc3545!important;
}

.icon-success {
  color: #39a523!important;
}

.icon-bulk-activity-action {
  font-size: 26px;
}

.icon-bulk-activity-action-container{
  text-align: center;
}

.cursor-link{
  cursor: pointer;
}

.DayPickerInput {
  width: 100%;
}

.DayPickerInput input {
  display: block;
  width: 100%;
  height: 42px;
  padding: 6px 12px;
  font-size: 16px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.activity-list-card{
  margin-bottom: 10px;
}

.activity-list-card-followup {
  background-color: #fff2df;
}

.verbatim-entry-container {
  border-radius: 2px;
  border: thin solid gray;
  padding: 5px;
}

.verbatim-entry-container-action {
  background-color:bisque;
}

.verbatim-entry-container-thought {
  background-color:bisque;
}

.verbatim-entry-container-dialog {
  background-color:#f8f8fc;
}

.verbatim-entry-container-note {
  background-color:bisque;
}

.verbatim-entry-container-other {
  background-color:bisque;
}

.modal-x-large {
  min-width: 96%;
}

.modal-large {
  width: 50%;
}

.text-severity-normal {
  color: #000000;
  font-weight: bold;
}

.text-severity-severe {
  color: #de8a1d;
  font-weight: bold;
}

.text-severity-critical {
  color: #8f0700;
  font-weight: bold;
}

.bulk-activity-row {
  margin-top: 5px;
  padding: 4px;
}

.bulk-activity-row-body {
  padding: .25rem;
}

.bulk-activity-row-header{
  margin-bottom: .15rem;
  color: black;
  font-weight: bold;
}

.bulk-activity-row-error {
  background-color: #f8d7da;
}

.bulk-activity-row-error-message {
  color: #721c24;
  font-weight: bold;
  margin-bottom: 5px;
}

.reminder-closed {
  text-decoration: line-through;
}

.dropzone {
  border: thin solid black;
  padding-top: 25px;
  text-align: center;
}

.import-history-job-detail-row {
  margin-bottom: 5px;
}

.actionable {
  cursor: pointer;
}

/* page settings */
.page-settings-row > label {
  font-weight: 600;
}